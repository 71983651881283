import Loading from "../../components/Loading";

export default function Quiz(props) {
  return (
    <article className="text-white p-4 py-11 flex flex-col gap-4 md:max-w-5xl">
      <h1 className="text-4xl font-bold text-center md:text-5xl lg:text-6xl max-w-[30ch]">
        Descubra a simpatia infalível para trazer seu ex de volta
      </h1>

      <article className="flex flex-col gap-3 md:gap-5">
        <h2 className="text-center font-bold text-2xl lg:text-3xl">
          Insira os nomes e descubra qual a simpatia ideal para vocês
        </h2>

        <form
          onSubmit={(event) =>
            props.handleQuizSubmit(
              event,
              props.quizData,
              props.setQuizData,
              props.setIsLoading
            )
          }
          className="flex flex-col gap-3 w-full md:gap-5"
        >
          <h3 className="text-base text-center font-medium">
            Insira os nomes nos campos abaixo:
          </h3>

          <div className="flex flex-col gap-3 md:flex-row">
            <div className="w-full flex flex-col gap-1">
              <input
                name="name"
                value={props.quizData.data.name}
                onChange={(event) =>
                  props.handleQuizData(event, props.setQuizData)
                }
                className="p-4 bg-white text-gray-600 text-lg w-full rounded-md"
                type="text"
                placeholder="Seu nome:"
              />
              {props.quizData.status.errors.nameError.error && (
                <p className="text-base text-red-500">
                  {props.quizData.status.errors.nameError.msg}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col gap-1">
              <input
                name="loverName"
                value={props.quizData.data.loverName}
                onChange={(event) =>
                  props.handleQuizData(event, props.setQuizData)
                }
                className="p-4 bg-white text-gray-600 text-lg w-full rounded-md"
                type="text"
                placeholder="Nome da pessoa amanda:"
              />

              {props.quizData.status.errors.loverNameError.error && (
                <p className="text-base text-red-500">
                  {props.quizData.status.errors.loverNameError.msg}
                </p>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="inline-block w-full bg-primary uppercase text-lg p-4 font-bold rounded-md hover:bg-red-700 transition-all duration-200 cursor-pointer"
          >
            Encontrar simpatia
          </button>
        </form>
      </article>

      {props.isLoading && (
        <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,.9)]">
          <article className="flex flex-col items-center gap-2 p-4">
            <h2 className="text-lg md:text-2xl lg:text-4xl text-white font-bold text-center">
              Calculando sua compatibilidade com seu parceiro.
            </h2>
            <Loading />
          </article>
        </div>
      )}
    </article>
  );
}
