import React, { useState, useEffect } from "react";

export default function RedirectToVideo(props) {
  const totalTime = 1600;

  const [progress, setProgress] = useState(0);

  const name = localStorage.getItem("name");
  const loverName = localStorage.getItem("loverName");

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + totalTime;

    const updateProgress = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const percentage = (elapsedTime / totalTime) * 98;

      setProgress(percentage);

      if (now < endTime) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);

    return () => {
      setProgress(0);
    };
  }, []);

  return (
    <section className="text-white">
      <article className="p-4 flex flex-col items-center gap-4">
        <div className="bg-white w-full max-w-[51rem] rounded-xl">
          <div className="loader-container rounded-xl">
            <div
              className="loader-bar flex flex-col justify-center items-end pr-4 rounded-l-lg"
              style={{
                animation: `loaderAnimation ${totalTime}ms linear forwards`,
              }}
            >
              <mark className="text-white bg-transparent text-base">
                {progress.toFixed(0)}%
              </mark>
            </div>
          </div>
        </div>

        <h1 className="font-bold text-4xl md:text-5xl">Parabéns</h1>

        <p className="text-white font-medium text-lg md:text-xl lg:text-2xl text-center max-w-[80ch]">
          {name} e {loverName}, vocês são um casal de alma gêmea. Clique no
          botão abaixo e descubra a simpatia certa capaz de trazer o seu amor de
          volta em 7 dias
        </p>

        <button
          type="button"
          onClick={() => handleRedirectToVideo(props.setIsVideo)}
          className="pulse-animation p-4 px-16 uppercase font-bold text-lg md:text-xl bg-primary rounded-2xl mt-4 transition-all duration-200 hover:bg-red-700"
        >
          Assistir vídeo da simpatia
        </button>
      </article>
    </section>
  );
}

function handleRedirectToVideo(setIsVideo) {
  setIsVideo(() => true);
}
