import { useEffect } from "react";

export default function Video() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://scripts.converteai.net/eafa96af-3b9a-4c97-bda7-b94aebd35e4a/players/65e3c22724757e0008558b16/player.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const name = localStorage.getItem("name");
  const loverName = localStorage.getItem("loverName");

  if (!localStorage.getItem("isVideo")) {
    localStorage.setItem("isVideo", "true");
  }

  return (
    <div className="w-full max-w-5xl text-white p-4 pb-10 flex flex-col gap-4">
      <h1 className="text-center font-bold text-3xl md:text-4xl lg:text-5xl">
        Simpatia infalível para {name} e {loverName}
      </h1>
      <p className="text-center text-base md:text-lg lg:text-xl">
        Faça essa simpatia e recupere seu amor em 7 dias
      </p>
      <article className="w-full mt-6">
        <div
          id="vid_65e3c22724757e0008558b16"
          style={{
            position: "relative",
            width: "100%",
            paddingTop: "56.25%",
            overflow: "hidden",
          }}
        >
          <img
            id="thumb_65e3c22724757e0008558b16"
            src="https://images.converteai.net/eafa96af-3b9a-4c97-bda7-b94aebd35e4a/players/65e3c22724757e0008558b16/thumbnail.jpg"
            alt="video thumbnail"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
          />
          <div
            id="backdrop_65e3c22724757e0008558b16"
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              WebkitBackdropFilter: "blur(5px)",
              backdropFilter: "blur(5px)",
            }}
          ></div>
        </div>
      </article>
    </div>
  );
}
