import { useState } from "react";
import Quiz from "./Quiz";
import RedirectToVideo from "./RedirectToVideo";
import Video from "../video/Video";

export default function Hero() {
  const [quizData, setQuizData] = useState(() => {
    return {
      data: {
        name: "",
        loverName: "",
      },
      status: {
        success: false,
        errors: {
          nameError: {
            error: false,
            msg: "Name Error.",
          },
          loverNameError: {
            error: false,
            msg: "Lover Name Error.",
          },
        },
      },
    };
  });

  const [isLoading, setIsLoading] = useState(() => false);

  const [isVideo, setIsVideo] = useState(() => {
    if (window.localStorage.getItem("isVideo")) {
      return true;
    }

    return false;
  });

  if (isVideo) {
    const name = localStorage.getItem("name");
    const loverName = localStorage.getItem("loverName");

    if (!name || !loverName) {
      window.localStorage.clear();
      setIsVideo(() => false);
    }
  }

  if (isLoading) {
    setTimeout(() => {
      setQuizData((prevQuizData) => {
        return {
          ...prevQuizData,
          status: {
            ...prevQuizData.status,
            success: true,
          },
        };
      });

      setIsLoading(() => false);
    }, 2000);
  }

  return (
    <section className="content-height flex flex-col items-center justify-center">
      {isVideo ? (
        <Video setIsVideo={setIsVideo} />
      ) : (
        <>
          {quizData.status.success ? (
            <RedirectToVideo setIsVideo={setIsVideo} />
          ) : (
            <Quiz
              quizData={quizData}
              setQuizData={setQuizData}
              handleQuizData={handleQuizData}
              handleQuizSubmit={handleQuizSubmit}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      )}
    </section>
  );
}

function handleQuizData(event, setQuizData) {
  const target = event.target;

  const targetValue = target.value;
  const targetName = target.name;

  setQuizData((prevQuizData) => {
    return {
      ...prevQuizData,
      data: {
        ...prevQuizData.data,
        [targetName]: targetValue,
      },
    };
  });
}

function handleQuizSubmit(event, quizData, setQuizData, setIsLoading) {
  event.preventDefault();

  validateQuizData(quizData, setQuizData, setIsLoading);
}

function validateQuizData(quizData, setQuizData, setIsLoading) {
  const minLength = 1;
  const maxLength = 150;
  const nameLength = quizData.data.name.length;
  const loverNameLength = quizData.data.loverName.length;

  const requiredErrorMessage = "Este campo é obrigatório.";
  const maxLengthErrorMessage = `Este campo não pode ter mais do que ${maxLength} caracteres.`;

  setQuizData((prevQuizData) => {
    let error = false;

    const newQuizData = { ...prevQuizData };

    if (nameLength < minLength) {
      error = true;

      newQuizData.status.errors.nameError.error = true;
      newQuizData.status.errors.nameError.msg = requiredErrorMessage;
    } else if (nameLength >= minLength && nameLength <= maxLength) {
      newQuizData.status.errors.nameError.error = false;
    }

    if (nameLength > maxLength) {
      error = true;

      newQuizData.status.errors.nameError.error = true;
      newQuizData.status.errors.nameError.msg = maxLengthErrorMessage;
    } else if (nameLength >= minLength && nameLength <= maxLength) {
      newQuizData.status.errors.nameError.error = false;
    }

    if (loverNameLength < minLength) {
      error = true;

      newQuizData.status.errors.loverNameError.error = true;
      newQuizData.status.errors.loverNameError.msg = requiredErrorMessage;
    } else if (loverNameLength >= minLength && loverNameLength <= maxLength) {
      newQuizData.status.errors.loverNameError.error = false;
    }

    if (loverNameLength > maxLength) {
      error = true;

      newQuizData.status.errors.loverNameError.error = true;
      newQuizData.status.errors.loverNameError.msg = maxLengthErrorMessage;
    } else if (loverNameLength >= minLength && loverNameLength <= maxLength) {
      newQuizData.status.errors.loverNameError.error = false;
    }

    if (!error) {
      localStorage.setItem("name", newQuizData.data.name);
      localStorage.setItem("loverName", newQuizData.data.loverName);
      setIsLoading(() => true);
    }

    return { ...newQuizData };
  });
}
